import { useEffect } from 'react';

const useFadeEffect = () => {
  useEffect(() => {
    const heroText = document.querySelector('.hero-content');

    function initialLoad() {
      setTimeout(() => {
        heroText.classList.add('fade-in');
      }, 300);
    }

    function elementFade() {
      let active = document.querySelector('.fade-in');

      if (window.pageYOffset < 350 && !active) {
        heroText.classList.remove('fade-out');
        heroText.classList.add('fade-in');
      }
      if (window.pageYOffset > 350 && active) {
        heroText.classList.remove('fade-in');
        heroText.classList.add('fade-out');
      }
    }

    window.addEventListener('load', initialLoad());
    window.addEventListener('scroll', elementFade);
  });
};

export default useFadeEffect;
