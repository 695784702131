import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Dots from '../components/dots';
import useFadeEffect from '../hooks/useFadeEffect';

const Hero = props => {
  useFadeEffect();

  return (
    <HeroContainer>
      <Dots />
      <div className='outline'></div>
      <HeroContent className='hero-content'>
        <HeroSubtext>Software Engineer</HeroSubtext>
        <HeroHeadline>Jon Miller 👋</HeroHeadline>
        <HeroDescription>
          I enjoy working with new tech, building UI's, automation, and working
          with motivated people.
        </HeroDescription>
        {/* <HeroButton to='/contact'>{props.ctaText}</HeroButton> */}
      </HeroContent>
    </HeroContainer>
  );
};

export const HeroContainer = styled.div`
  height: 100vh;
  min-height: 700px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: ${props => props.theme.color.darkPurple};

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    min-height: 900px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding: 75px 25px 50px 25px;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (max-width: ${props => props.theme.breakpoints.small}) {
      height: 90% !important;
    }
  }

  div {
    h1 {
      font-size: 2.5rem;
      color: ${props => props.theme.color.white};
    }

    h3 {
      color: ${props => props.theme.color.peach};
    }
  }

  img {
    filter: contrast(1);
  }

  .hero-image {
    border-radius: 45% 60% 40% 70% / 60% 45% 60% 45%;
    width: 400px;
    height: 500px;
    z-index: 1;
    animation: move 10s ease-in-out infinite;
    transform-origin: 50% 50%;
    margin-bottom: 50px;
    box-shadow: 12px 15px 0px ${props => props.theme.color.peach};
    position: relative;
    background: ${props => props.theme.color.lightPurple};

    &:hover {
      transform: translate(-10px, -10px);
      ${props => props.theme.color.lightPurple};
      box-shadow: 22px 25px 0px ${props => props.theme.color.lightPurple};
      transition: all 0.5s ease;
      background: ${props => props.theme.color.peach};
    }

    @media (max-width: ${props => props.theme.breakpoints.small}) {
      width: 350px;
      height: 390px;
    }
  }
`;

export const HeroContent = styled.div`
  text-align: center;
  width: 600px;
  margin: auto;
  position: relative;
  opacity: 0;
  transition: opacity 1s ease-in;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
  }
`;

export const HeroSubtext = styled.h5`
  font-size: 1rem;
  font-weight: ${props => props.theme.font.weight.regular};
  color: ${props => props.theme.color.lightPurple};
  text-align: center;
  margin-bottom: 20px;
  text-transform: lowercase;
`;

export const HeroHeadline = styled.h1`
  margin-bottom; 25px;
`;

export const HeroDescription = styled.p`
  color: ${props => props.theme.color.white};
  line-height: 1.7;
  opacity: 0.7;
  margin-bottom: 75px;
`;

export const HeroButton = styled(Link)`
  padding: 25px 25px 25px 25px;
  background-color: ${props => props.theme.color.lightPurple};
  color: ${props => props.theme.color.white};
  border-radius: 60% 40% 60% 40%;
  border: none;
  font-size: 1rem;
  line-height: 1.2;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    background-color: ${props => props.theme.color.peach};
    transition: all 0.3s ease;
  }
`;

Hero.propTypes = {
  firstName: PropTypes.string,
  role: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
};

Hero.defaultProps = {
  firstName: 'Jon',
  role: 'lets create together',
  description: 'Enter Description Here',
  ctaText: 'Hire Me',
};

export default Hero;
