import React from 'react';
import { graphql } from 'gatsby';
// import RemarkForm from '@tinacms/gatsby-tinacms-remark';

import Layout from '../components/global/layout';
import SEO from '../components/global/seo';
import Hero from '../components/hero';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title='Jon Miller | Software Engineer' />
      <Hero role='Jon Miller' />
    </Layout>
  );
};

export default IndexPage;
